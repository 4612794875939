
(function () {

    class ImageLoader {
        constructor() {

            this.srcs = document.getElementsByClassName("load-src");
            this.imageArray = [];
            for (let i = 0; i < this.srcs.length; i++) {
                this.imageArray.push(this.srcs[i].src);
            }
        }

        async loadImages() {

            return new  Promise(resolve => {

                let loaded = 0;

                for (let i = 0; i < this.imageArray.length; i++) {
                    const image = new Image();
                    
                    image.addEventListener('load', () => {
                        loaded++;
                        if (loaded == this.imageArray.length) resolve();
                    });
                    image.src = `${this.imageArray[i]}`; 
                }
            });

        }
    }

    class Main {
        constructor() {
            
            this.imageLoader = new ImageLoader();
            this.windowFrame = document.getElementById("window-frame");
            this.windowMask = document.getElementById("window-mask");
            this.windowText = document.getElementById("window-text");
            this.window = document.getElementById("window-container");
            this.windowInner = document.getElementById("window-inner");
            this.header = document.getElementById("header");
            this.app = document.getElementById("app");
            this.arrow = document.getElementById("intro-arrow");

            this.header.style.opacity = 0;

            setTimeout(() => {
                this.imageLoader.loadImages().then(() => {

                    this.windowFrame.style.transition = "1s ease-in-out";
                    this.windowText.style.transition = "1s ease-in-out";

                    setTimeout(() => {
                        this.header.style.transition = "0.6s 2s";
                        this.startIntro();
                    }, 1000);
                });   
            }, 500);         
        }

        startIntro() {

            this.windowFrame.style.opacity = 1;
            this.windowText.style.opacity = 1;

            this.windowInner.style.transition = "1s 0.5s ease-in-out";
            this.windowMask.style.transition = "1s 1.5s ease-in-out";
            
            setTimeout(() => {
                this.windowText.style.opacity = 0;
                this.windowInner.style.transform = "translateY(40%)";
                this.windowMask.style.backgroundColor = "rgba(255, 255, 255, 0";
            }, 1500);

            setTimeout(() => {
                this.window.classList.add("active");
                // this.app.classList.add("active");
                this.header.style.opacity = 1;

                this.arrow.addEventListener("click", () => {
                    const element = document.getElementById("main");
                    element.scrollIntoView();
                });
            }, 3000);
        }
    
    }

    const main = new Main();
})();